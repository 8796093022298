<template>
    <v-row no-gutters>
        <v-col cols="4" class="pr-4">
            <v-card class="elementStat py-6 px-2" depressed rounded="lg" elevation="1">
                <v-icon color="primary" large>$sackdollar_d</v-icon>
                <h3 class="mt-4">{{typeof(budget) != "number" ? 0 : budget}}&nbsp;€</h3>
                <span class="label mt-3">{{ $t('budget') }}
                
                </span>
            </v-card>
        </v-col>
        <v-col cols="4" class="pr-4">
            <v-card class="elementStat py-6 px-2" depressed rounded="lg" elevation="1">
                <v-icon color="primary" large>$userchart_d</v-icon>
                <h3 class="mt-4">{{!isNaN(mission.costperleads) ? mission.costperleads.toFixed(2) : "-"}}&nbsp;€</h3>
                <span class="label mt-3">{{ $t('leadCost') }}</span>
            </v-card>
        </v-col>
        <v-col cols="4">
            <v-card class="elementStat py-6 px-2" depressed rounded="lg" elevation="1">
                <v-icon color="primary" large>$arrow_pointer_d</v-icon>
                <h3 class="mt-4">{{mission.ctr.toFixed(2)}}&nbsp;%</h3>
                <span class="label mt-3">CTR
                    <v-tooltip right content-class="white--tooltip" max-width="300">
                        <template v-slot:activator="{ on, attrs }">
                            <v-icon color="info" class="ml-2" v-bind="attrs" v-on="on">$circleinfo_d</v-icon>
                        </template>
                        <span>
                            <span><b>{{ $t('titleCtr') }}</b><br></span>
                            <span v-html="$t('descriptionCtr')"></span>
                        </span> 
                    </v-tooltip>
                </span>
            </v-card>
        </v-col>
    </v-row>
</template>

<script>
import dateManipulation from '@/mixins/dateManipulation';
import GenericDataService from '@/services/GenericDataService';
import NumberStatCardComponent from '@/components/dashboardComponent/NumberStatCardComponent.vue';

export default {
    name: "statsAcquisitionComponent",
    components: { NumberStatCardComponent },
    mixins:[dateManipulation],
    props:['mission', 'budget'],
    data() {
        return {}
    },
    mounted() {},
    watch: {},
    methods: {}
}
</script>

<style lang="scss">
.elementStat {
    width: 100%;
    text-align: center;
    h3 {
        color: inherit !important;
        font-weight: 800;
        font-size: 30px;
        @media(-webkit-device-pixel-ratio: 1.25) {
            font-size: 30px;
            margin-top: 10px;
        }
        span {
            font-size: 15px;
            font-weight: 400;
        }
    }
    .label {
        display: block;
        font-size: 13px;
    }
}
</style>