<template>
    <v-container v-if="mission.sousType !== 'missionACQUISITION'" fluid class="pa-6">
        <v-row>
            <v-col cols="4">
                <DetailComponent :mission="mission" class="mb-4" />
                <PlaceComponent v-if="mission.config && mission.config.place" :mission="mission" :place="mission.config.place" class="mb-4" />
                <v-row class="" v-if='mission.config && mission.config.canal && (["TEL"]).includes(mission.config.canal)'>
                    <v-col cols="6" v-if="$func.hasRight('retroplanning/get_objective')">
                        <rdvObjective :data="statCampCall" :key="'camp_call_' + statsCallKey"/>
                    </v-col>
                    <v-col cols="6">
                        <treatmentStats :data="statTreatment" :key="'camp_treatment_' + statsCallKey"/>
                    </v-col>
                    <v-col cols="6" v-if="mission.config.prestataire_type === 'VENDORS'">
                        <campDonutComponent :title="'statsRdvVendor'" :data="statRdvVendor" :key="'camp_rdvVendor_' + statsCallKey"/>
                    </v-col>
                    <v-col cols="6">
                        <campDonutComponent :title="'statsFailMotif'" :data="statFailMotive" :key="'camp_fail_' + statsCallKey"/>
                    </v-col>
                </v-row>
            </v-col>
            <v-col cols="8">
                <v-card class="mb-4" v-for="table in tables" :key="table.name + key">
                    <v-card-title class="titleIdComponent">
                        <h2 class="mb-0" style="width:100%; align-items:center;">
                            {{ $t(table.name) }}
                            <v-spacer></v-spacer>
                        </h2>
                    </v-card-title>
                    <DatatableV2 :displayTitle="false" :flat="true" :forceDisablePagination="true" :forceDisableSort="true" :shiftButton="true" :url="table.apiUrl" />
                </v-card>
            </v-col>
        </v-row>
        
    </v-container>
    <v-container v-else fluid class="pa-6">
        <v-row no-gutters class="mb-4">
            <v-col cols="12">
                <DetailsAcquisitionComponent :mission="mission" @edit="getForm(editUrl)" />
            </v-col>
        </v-row>
        <v-row no-gutters>
            <v-col cols="4">
                <templateAcquisitionsComponent :mission="mission" class="mb-4 mr-4" />
                <TunnelConversionComponent :mission="statsAcquis" class="mb-4 mr-4" />
            </v-col>
            <v-col cols="8">
                <StatsAcquisitionComponent :mission="statsAcquis" :budget="mission.budget" class="mb-4" />
                <detailsCanalAcquisitionComponent :mission="mission" class="mb-4" />
                <v-row class="" no-gutters>
                    <v-col cols="6">
                        <campDonutComponent class="mr-4" :to_trad="true" :title="'leadsMetaByGender'" :data="statsGender" :isAcqui="true"/>
                    </v-col>
                    <v-col cols="6">
                        <campDonutComponent :to_trad="true" :title="'leadsMetaByAgeRange'" :data="statsAgeRange" :isAcqui="true"/>
                    </v-col>
                </v-row>
                <v-card rounded="lg" class="rounded-lg pa-3 pt-0 mt-4" v-if="mission.config.performance_analysis">
                    <v-card-title class="">
                        <h2 class="mb-0" style="width:100%; align-items:center;">
                            {{ $t('performance_analysis') }}
                        </h2>
                    </v-card-title>
                    <v-card-text>
                        {{ mission.config.performance_analysis }}
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
        <FormDialogComponent v-if="form" ref="componentForm" :type="'edit'" :form="form" :action="'SET_DATA'" />
        <dialogProgramRpComponent v-if="mission.id" :openProgramRpDialog="openProgramRpDialog" :retroplanning="mission" @refresh="refresh" @close="openProgramRpDialog = false"/>
    </v-container>
</template>

<script>
import DetailComponent from '@/modules/missions/missionsComponents/detailComponent';
import DetailsAcquisitionComponent from '@/modules/missions/missionsComponents/acquisitionComponents/DetailsAcquisitionComponent';
import PlaceComponent from '@/modules/missions/missionsComponents/placeComponent';
import TableComponent from "@/modules/missions/missionsComponents/tableComponent";
import DatatableV2 from '@/components/DatatableV2';
import GenericDataService from '@/services/GenericDataService'
import rdvObjective from '@/modules/operations/operationsComponents/rdvObjective.vue';
import treatmentStats from '@/modules/operations/operationsComponents/treatmentStats.vue';
import campDonutComponent from '@/modules/operations/operationsComponents/campDonutComponent.vue';
import templateAcquisitionsComponent from '@/modules/missions/missionsComponents/acquisitionComponents/templateAcquisitionsComponent.vue';
import StatsAcquisitionComponent from '@/modules/missions/missionsComponents/acquisitionComponents/statsAcquisitionComponent.vue';
import detailsCanalAcquisitionComponent from '@/modules/missions/missionsComponents/acquisitionComponents/detailsCanalAcquisitionComponent.vue';
import TunnelConversionComponent from '@/modules/missions/missionsComponents/acquisitionComponents/tunnelConversionComponent.vue';
import FormDialogComponent from "@/components/dialogs/FormDialogComponent";
import getForm from '@/mixins/mixins';
import refreshData from '@/mixins/mixins';
import dialogProgramRpComponent from '@/components/dialogs/dialogProgramRpComponent.vue';

export default {
    name: "MissionDetailDashboard",
    components: { 
        DetailComponent, 
        PlaceComponent, 
        TableComponent, 
        DatatableV2, 
        rdvObjective, 
        treatmentStats, 
        campDonutComponent, 
        DetailsAcquisitionComponent, 
        templateAcquisitionsComponent, 
        StatsAcquisitionComponent, 
        detailsCanalAcquisitionComponent, 
        TunnelConversionComponent,
        FormDialogComponent,
        dialogProgramRpComponent
    },
    mixins:[refreshData, getForm],
    data() {
        return {
            tables: [],
            key: 0,
            statsCallKey:0,
            statCampCall: {},
            statTreatment: {},
            statRdvVendor: {},
            statFailMotive: {},
            statsGender: {
                nbFemmes: 0,
                nbHommes: 0
            },
            statsAgeRange: {
                nb18_24: 0,
                nb25_34: 0,
                nb35_44: 0,
                nb45_54: 0,
                nb55_64: 0,
                nb65: 0
            },
            statsAcquis: {
                costperleads: '-',
                ctr: 0,
                impressions: 0,
                clics: 0,
                leads: 0,
                rdv: 0,
                venus: 0,
                ventes: 0
            },
            editUrl: "/mission/getFormRealRetroplanning?id=" + this.$route.params.mission_id,
            apiUrl: "/mission/get?id=" + this.$route.params.mission_id,
            mutation: "missions/setMissionData",
            form: null,
        };
    },
    created() {},
    methods: {

        refresh(){
            this.$store.dispatch("campaign/SET_PROGRAM_RP_DIALOG", false);
            this.refreshData();
        }
    },
    watch: {
        mission(val){
            if(val && val.type == 'CAMP_CALL') {
                GenericDataService.getData('/mission/getCampCallStats?id=' + this.$route.params.mission_id).then((response) => {
                    this.statCampCall = response.data.data.statsRdv;
                    this.statTreatment = response.data.data.statsTreatment;
                    this.statRdvVendor = response.data.data.statsRdvVendor;
                    this.statFailMotive = response.data.data.statsMotifFail;
                    this.statsCallKey++;
                }); 
            }

            if(!val.id)
                this.tables = [];

            let tablesGenerated = [
                {
                    "name": "contractor",
                    "apiUrl": "/mission/getContractor?id=" + val.id,
                },
                {
                    "name": "customers",
                    "apiUrl": "/mission/getCustomers?id=" + val.id,
                }
            ];

            if(val.config.staff_qty) {
                tablesGenerated.push({
                    "name": "assignedToTheMission",
                    "apiUrl": "/mission/getAssigned?id=" + val.id
                })
            }
            this.tables = tablesGenerated;
            this.key++;
            
            if(val.sousType == 'missionACQUISITION' && val.config.stats) {
                for (let [key, value] of Object.entries(val.config.stats)) {
                    if(key !='rpid' && key != "leads" && key != 'kpiGlobalMeta') {
                        this.statsAcquis.ctr += isNaN(value.ctr) ? 0 : value.ctr;
                        this.statsAcquis.impressions += isNaN(value.nbImpression) ? 0 : value.nbImpression;
                        this.statsAcquis.clics += isNaN(value.nbClics) ? 0 : value.nbClics;
                        this.statsAcquis.leads += isNaN(value.nbleads) ? 0 : value.nbleads;
                    }
                    if(key == 'kpiGlobalMeta') {
                        console.log('burp', value)
                        this.statsGender.nbFemmes = isNaN(value.nbFemmes) ? 0 : value.nbFemmes;
                        this.statsGender.nbHommes = isNaN(value.nbHommes) ? 0 : value.nbHommes;

                        this.statsAgeRange.nb18_24 = isNaN(value.nb1824ans) ? 0 : value.nb1824ans;
                        this.statsAgeRange.nb25_34 = isNaN(value.nb2534ans) ? 0 : value.nb2534ans;
                        this.statsAgeRange.nb35_44 = isNaN(value.nb3544ans) ? 0 : value.nb3544ans;
                        this.statsAgeRange.nb45_54 = isNaN(value.nb4554ans) ? 0 : value.nb4554ans;
                        this.statsAgeRange.nb55_64 = isNaN(value.nb5564ans) ? 0 : value.nb5564ans;
                        this.statsAgeRange.nb65 = isNaN(value.nbPlus65ans) ? 0 : value.nbPlus65ans;
                    }
                }
                if (this.statsAcquis.leads > 0 && val.budget > 0) {
                    this.statsAcquis.costperleads = val.budget / this.statsAcquis.leads;
                } 
            }
        }
    },
    computed: {
        mission: function(){ 
            return this.$store.state.missions.mission
        },
        openProgramRpDialog: {
            get: function(){
                return this.$store.state.campaign.programRpDialog;
            },
            set: function(val){
                this.$store.dispatch("campaign/SET_PROGRAM_RP_DIALOG", false);
            }
        }
    },
};
</script>

<style lang="scss">
.tunnelConversion {
    padding: 10px;
    border-radius: 25px;
    font-size: 18px;
    font-weight: 600;
    text-align: center;
    margin: 5px;
    color: white;
}
</style>
