<template>
      <v-card rounded="lg">
        <v-card-title class="">
            <h2 class="mb-0 text-uppercase" style="width:100%; align-items:center;">
                Détail par canal de diffusion
            </h2>
        </v-card-title>
    
        <v-simple-table>
            <template v-slot:default>
            <thead>
                <tr>
                    <th class="text-left">
                        {{ $t('canal') }}
                    </th>
                    <th class="text-left">
                        {{ $t('leads') }}
                    </th>
                    <th class="text-left">
                        CTR
                    </th>
                    <th class="text-left">
                        % du budget
                    </th>
                </tr>
            </thead>
            <tbody v-if="mission.config.stats">
                <tr
                v-for="[key, value] of Object.entries(mission.config.stats)"
                :key="key"
                v-if="key !== 'rpid' && key !== 'leads' && key !== 'lead' && key !== 'kpiGlobalMeta'"
                >
                    <td>{{ key }}</td>
                    <td>{{ value.nbleads }}</td>
                    <td>{{ value.ctr.toFixed(2) }}&nbsp;€</td>
                    <td>{{ value.TxBudget }}&nbsp;%</td>
                </tr>
            </tbody>
            <tbody v-else>
                <tr>
                    <td colspan="4" class="text-center">
                        {{ $t('noData') }}
                    </td>
                </tr>
            </tbody>
            </template>
        </v-simple-table>
    </v-card>
</template>

<script>
import dateManipulation from '@/mixins/dateManipulation';
import GenericDataService from '@/services/GenericDataService';

export default {
    name: "detailsCanalAcquisitionComponent",
    mixins:[dateManipulation],
    props:['mission'],
    data() {
        return {}
    },
    created() {},
    mounted() {},
    watch: {},
    methods: {}
}
</script>

<style lang="scss">

</style>