<template>
<v-card rounded="lg" class="rounded-lg pa-3 pt-0">
    <v-card-title class="px-3">
        <div class="iconEdit" v-if="this.$func.hasRight('retroplanning/update')">
            <v-btn :disabled="!(['DRAFT', 'PROVIDER_REFUSAL'].includes(mission.status) || this.$store.state.auth.user.is_presta)" text depressed class="square" @click="$emit('edit')">
                <v-icon small>$pencil_l</v-icon>
            </v-btn>
        </div>
    </v-card-title>
    <v-card-text class="px-3">
            <div class="configurationContent">
                <div class="block text-center">
                    <div class="pb-1 containerConf">
                        <v-row>
                            <v-col cols="4">
                                <div class="configContainer my-1">
                                    <div class=" text-left">
                                        <p class="mb-0 mr-2">
                                            <b>{{ $t('campaignReference') }} :</b>
                                            {{mission.reference}}
                                        </p>
                                    </div>
                                </div>
                                <div class="configContainer my-1">
                                    <div class=" text-left">
                                        <p class="mb-0 mr-2">
                                            <b>{{ $t('mission_date') }} :</b>
                                            Du {{parseFullDateToFr(mission.config.date_start)}} au {{parseFullDateToFr(mission.config.date_end)}}
                                        </p>
                                    </div>
                                </div>
                                <div class="configContainer my-1">
                                    <div class=" text-left">
                                        <p class="mb-0 mr-2"><b>{{$t('campaign')}} :</b> {{mission.operation_id.label}}</p>
                                    </div>
                                </div>
                            </v-col>
                            <v-col cols="4">
                                <div class="configContainer my-1">
                                    <div class=" text-left">
                                        <p class="mb-0 mr-2">
                                            <b>{{ $t('customer') }} :</b>
                                            <span> {{ mission.customer }} </span>
                                            <span v-if="mission.entities && mission.entities.length > 0">
                                                <span v-for="(entity, index) in mission.entities">- {{ entity.text }}</span>
                                            </span>
                                        </p>
                                    </div>
                                </div>
                                <div class="configContainer my-1"  v-if="mission.programmed_by">
                                    <div class=" text-left">
                                        <p class="mb-0 mr-2">
                                            <b>{{ $t('missioncontractor') }} :</b>
                                            <span v-if="mission.programmed_by"> {{ mission.programmed_by.firstname }} {{ mission.programmed_by.name }}</span>
                                            <span v-else> {{ mission.programmed_by.firstname }} {{ mission.programmed_by.name }}</span>
                                        </p>
                                    </div>
                                </div>
                            </v-col>
                            <v-col cols="4">
                                <div class="configContainer my-1" v-if="mission.config.objective">
                                    <div class="text-left">
                                        <p class="mb-0 mr-2">
                                            <b>{{ $t('objective') }} :</b>
                                            <span> {{ $t('formobjective' + mission.config.objective) }}</span>
                                        </p>
                                    </div>
                                </div>
                                <div class="configContainer my-1" v-if="mission.config.prestataire_type">
                                    <div class="text-left">
                                        <p class="mb-0 mr-2">
                                            <b>{{ $t('leads_processed_by') }} :</b>
                                            <span v-if="mission.config.prestataire_type === 'CALL'"> {{ mission.config.prestataire.text }}</span>
                                            <span v-else-if="mission.config.prestataire_type === 'VENDORS'"> {{ $t('vendors') }}</span>
                                            <span v-if="mission.config.prestataire_type === 'UYL'"> UpYourLeads</span>
                                        </p>
                                    </div>
                                </div>
                            </v-col>
                        </v-row>
                    </div>
                </div>
            </div>
    </v-card-text>
</v-card>
</template>

<script>
import dateManipulation from '@/mixins/dateManipulation';
import GenericDataService from '@/services/GenericDataService';

export default {
    name: "DetailsAcquisitionComponent",
    mixins:[dateManipulation],
    props:['mission'],
    data() {
        return {}
    },
    created() {},
    mounted() {},
    watch: {},
    methods: {}
}
</script>

<style lang="scss">
    .containerConf{
        display:flex;
        flex-wrap: wrap;
    }

    .configContainer{
        width:100%;
    }
    
    .configurationContent {
        color:rgba(0,0,0,.87);
        //height:100%;
        //margin-top:30px;   
    }

    .iconEdit {
        position:absolute;
        top:16px;
        right:24px;
    }
</style>